import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import teamEfficiency from "../images/team-efficiency.png";
import rrtImage from "../images/workflow-view.png";
import galaxyTags from "../images/history-tags.png";

import { HeaderSection, SectionBreak, InfoSection } from "../components/sections";

const LoginPage = () => (
    <Layout>
        <SEO title="Run an Efficient R&D Lab Environment" />
        <HeaderSection
            title="Run an Efficient R&D Lab Environment"
            subtitle="Enable researchers and technicians to focus on analyzing their data instead of expending time on software installation and system maintenance."
        />
        <SectionBreak
            title="Improve the Efficiency of Your Team"
            subtitle="Empower your team with the tools they need to focus on and accomplish their work."
        />
        <InfoSection
            image={teamEfficiency}
            imageShadow={false}
            facts={[
                {
                    title: "Provide Task-specific Tooling",
                    blurb: "Galaxy Pro offers a versatile toolbox that can be tailored to the analysis needs of a specific lab. Provide your team with the right tool for the job.",
                },
                {
                    title: "Lower Technological Friction",
                    blurb: "Software is time consuming to install and maintain, particularly research tools. Galaxy Pro offers a validated set of tools that we maintain, allowing your team to focus on using them instead of installing them.",
                },
                {
                    title: "Reduce Risk",
                    blurb: "Galaxy is an established, world-recognized platform that is being used by tens of thousands of users every month. Adopting it in your lab provides a battle-tested platform that can handle everything from mainstream analyses to lab-specific corner cases.",
                },
            ]}
        />
        <SectionBreak
            title="Increase Robustness, Reproducibility, and Transparency"
            subtitle="Galaxy Pro automatically tracks analysis steps, allowing analyses to be more easily reproduced and reports generated."
        />
        <InfoSection
            image={rrtImage}
            imageOnLeft={false}
            facts={[
                {
                    title: "Galaxy Pro SaaS",
                    blurb: "Galaxy Pro is available as Software-as-a-Service, meaning we take care of the infrastructure and and day-to-day management tasks to deliver a robust service that is ready to use, day or night.",
                },
                {
                    title: "Stay in Sync",
                    blurb: "Genomic data analysis is perplexed with tool and parameter selection. Galaxy Pro automatically tracks all of this so repeating an analysis or passing it on to someone else is well-documented.",
                },
                {
                    title: "Ready-to-Use Reports",
                    blurb: "Each invocation of a pipeline can generate a customizable report with inputs, parameters, and outputs that be exported into a PDF for easy distribution and inspection.",
                },
            ]}
        />
        <SectionBreak
            title="Save an FTE Salary?"
            subtitle="Galaxy Pro is intuitive and empowering, allowing researchers to do their own analysis without the technical hurdles, enabling them to do more. "
        />
        <InfoSection
            image={galaxyTags}
            facts={[
                {
                    title: "Enable Researchers to Analyze their Data",
                    blurb: "A researcher seeing the data they generated and having an opportunity to interactively explore it, yields more value from the data. Galaxy Pro enables this.",
                },
                {
                    title: "Share Knowledge",
                    blurb: "Galaxy Pro can be used as a training tool between researchers, bioinformaticians, and technicians, allowing them each to increasingly perform more tasks with less help.",
                },
                {
                    title: "No IT",
                    blurb: "Galaxy Pro runs in the cloud as a managed service. You do not need a system administrator managing local hardware or installing and maintaining software.",
                },
            ]}
        />
    </Layout>
);

export default LoginPage;
